// Core
import React from "react"
import PropTypes from "prop-types"

const Loader = ({ size, color, fullpage }) => {
  const loaderColor = {
    borderColor: `${color} transparent transparent transparent`,
  }
  const isFullpage = fullpage
    ? {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }
    : ""

  return (
    <div
      className="loader"
      style={{ width: size, height: size, ...isFullpage }}
    >
      <div style={loaderColor}></div>
      <div style={loaderColor}></div>
      <div style={loaderColor}></div>
      <div style={loaderColor}></div>
    </div>
  )
}

Loader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}

Loader.defaultProps = {
  size: "30px",
  color: "#fff",
  fullpage: false,
}

export default Loader
