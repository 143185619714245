// Core
import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

// Icons
import { BiErrorCircle, BiInfoCircle } from "react-icons/bi"
import { BsCheckCircle } from "react-icons/bs"

const icon = {
  info: <BiInfoCircle />,
  error: <BiErrorCircle />,
  success: <BsCheckCircle />,
}

const Alert = ({ type, text }) => {
  return (
    <div className={clsx("alert", `alert__${type}`)}>
      <div className={clsx("icon", `icon__${type}`)}>{icon[type]}</div>
      <span className={clsx("text", `text__${type}`)}>{text}</span>
    </div>
  )
}

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Alert
