// Core
import React from "react"
import { Link } from "gatsby"

// Icons
import { BsArrowRightShort } from "react-icons/bs"
import BannerLeftIcon from "../assets/icons/banner-left.inline.svg"
import BannerRightIcon from "../assets/icons/banner-right.inline.svg"

const Banner = () => {
  return (
    <section className="banner">
      <BannerLeftIcon className="banner__icon banner__icon--left" />
      <BannerRightIcon className="banner__icon banner__icon--right" />
      <h2 className="banner__title">Get started with Outloud</h2>
      <p className="banner__subtitle">
        Get full transparency on what is happening on the front lines of your
        business in real-time
      </p>
      <Link to="/demo" className="banner__link">
        <button className="banner__button">
          Request a Demo
          <BsArrowRightShort className="banner__button-arrow" />
        </button>
      </Link>
    </section>
  )
}

export default Banner
