// Core
import React, { useContext } from "react"
import PropTypes from "prop-types"
import { GatsbyContext } from "../context/context"
import { Link } from "gatsby"

// Components
import { Wrapper } from "./"

// Icons
import LogoIcon from "../assets/icons/logo.inline.svg"

const Footer = ({ siteTitle }) => {
  const {
    links: { FOOTER_LINKS },
  } = useContext(GatsbyContext)

  return (
    <footer className="footer">
      <Wrapper>
        <div className="footer__row">
          <div className="footer__logo-wrapper">
            <Link to="/" className="footer__logo">
              <LogoIcon className="footer__logo--icon" />
              <span className="footer__logo--text">{siteTitle}</span>
            </Link>
            <span className="footer__copy">
              © Copyright {new Date().getFullYear()}. All rights reserved
            </span>
          </div>

          <nav className="footer__nav" role="navigation">
            <ul className="footer__nav-list">
              {FOOTER_LINKS.map(({ id, url, label }) => {
                return (
                  <li key={id} className="footer__nav-item">
                    <Link to={url} className="footer__nav-link">
                      {label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
      </Wrapper>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: "",
}

export default Footer
