// Core
import React, { useState, createContext } from "react"

// Constants
import { HEADER_LINKS, FOOTER_LINKS, HIDDEN_LINKS } from "../constants/links"

const GatsbyContext = createContext()

const GatsbyProvider = ({ children }) => {
  const [links] = useState({ HEADER_LINKS, FOOTER_LINKS, HIDDEN_LINKS })

  return (
    <GatsbyContext.Provider value={{ links }}>
      {children}
    </GatsbyContext.Provider>
  )
}

export { GatsbyContext, GatsbyProvider }
