// Core
import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { GatsbyContext } from "../context/context"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import clsx from "clsx"

// Components
import { Wrapper } from "./"

// Icons
import LogoIcon from "../assets/icons/logo.inline.svg"

const Navbar = ({ siteTitle }) => {
  const [open, setIsOpen] = useState(false)
  const {
    links: { HEADER_LINKS },
  } = useContext(GatsbyContext)

  const toggleNav = () => setIsOpen(!open)

  useEffect(() => {
    if (open) {
      document.body.style.position = "fixed"
    }
    return () => {
      document.body.style.position = "static"
    }
  }, [open])

  return (
    <header className="navbar">
      <Wrapper>
        <div className="ycombinator">
          <StaticImage
            src="../assets/yc-logo.png"
            alt="Y Combinator"
            quality="100"
            width={120}
            placeholder="none"
          />
        </div>
        <div className="navbar__row">
          <Link to="/" className="navbar__logo">
            <LogoIcon className="navbar__logo--icon" />
            <span className="navbar__logo--text">{siteTitle}</span>
          </Link>

          <div
            className={clsx("open-main-nav", open ? "is-open" : "")}
            onClick={toggleNav}
            onKeyDown={toggleNav}
            role="button"
            tabIndex={0}
            aria-label="burger-icon"
          >
            <div className="burger__wrapper">
              <span className="burger"></span>
              <span className="burger"></span>
              <span className="burger"></span>
            </div>
            <span className="burger-text">Menu</span>
          </div>

          <nav
            className={clsx("nav", open ? "is-open" : "")}
            role="navigation"
            aria-label="main-navigation"
          >
            <ul className="nav__list">
              {HEADER_LINKS.map(({ id, url, label }) => {
                return (
                  <li key={id} className="nav__item">
                    <Link
                      to={url}
                      className="nav__link"
                      activeClassName="nav__link--active"
                    >
                      {label}
                    </Link>
                  </li>
                )
              })}
            </ul>
            <li className="nav__item nav__item--mobile">
              <Link
                to="/demo"
                className="nav__link"
                activeClassName="nav__link--active"
              >
                Request a demo
              </Link>
            </li>
            <Link to="/demo" className="navbar__link">
              <button className="navbar__btn">Request a demo</button>
            </Link>
          </nav>
        </div>
      </Wrapper>
    </header>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: "",
}

export default Navbar
