// Core
import React, { useState, useRef } from "react"
import PropTypes from "prop-types"

// Icons
import { FiChevronDown } from "react-icons/fi"
import { BsArrowRightShort } from "react-icons/bs"
import JobPostedIcon from "../assets/icons/job-posted.inline.svg"

const Accordion = ({ title, role, posted, children }) => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setRotate, setRotateState] = useState("accordion__icon")

  const contentRef = useRef(null)

  const toggleAccordion = () => {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${contentRef.current.scrollHeight}px`
    )
    setRotateState(setActive === "active" ? "" : "rotate")
  }

  const stopPropogation = e => e.stopPropagation()

  return (
    <div className="accordion">
      <button
        className={`accordion__heading ${setActive}`}
        onClick={toggleAccordion}
      >
        <div className="accordion__heading--left">
          <div className={`accordion__icon--wrapper ${setRotate}`}>
            <FiChevronDown className="accordion__icon" />
          </div>
          <div className="accordion__role">
            <p className="accordion__role--title">{title}</p>
            <p className="accordion__role--description">{role}</p>
          </div>
        </div>

        <div className="accordion__heading--right">
          <div className="accordion__posted">
            <JobPostedIcon className="accordion__posted--icon" />
            <span className="accordion__posted--text">Posted {posted} ago</span>
          </div>

          <a
            href="mailto:team@outloud.ai"
            className="accordion__link"
            onClick={stopPropogation}
          >
            Contact Us
            <BsArrowRightShort className="accordion__link--arrow" />
          </a>
        </div>
      </button>

      <div
        ref={contentRef}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div className="accordion__content--wrapper">{children}</div>
      </div>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  posted: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Accordion
