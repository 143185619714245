const HEADER_LINKS = [
  {
    id: 1,
    label: "home",
    url: `/`,
  },
  // {
  //   id: 2,
  //   label: "product",
  //   url: `/product`,
  // },
  {
    id: 3,
    label: "company",
    url: `/company`,
  },
  {
    id: 4,
    label: "contact us",
    url: `/contact`,
  },
]

const FOOTER_LINKS = [
  // {
  //   id: 1,
  //   label: "product",
  //   url: `/product`,
  // },
  {
    id: 2,
    label: "company",
    url: `/company`,
  },
  {
    id: 3,
    label: "contact us",
    url: `/contact`,
  },
  {
    id: 4,
    label: "terms of use",
    url: `/terms`,
  },
  {
    id: 5,
    label: "privacy policy",
    url: `/privacy`,
  },
]

const HIDDEN_LINKS = [
  {
    id: 1,
    label: "challenges",
    url: `/challenges`,
  },
  {
    id: 2,
    label: "gifts",
    url: `/gifts`,
  },
]

export { HEADER_LINKS, FOOTER_LINKS, HIDDEN_LINKS }
