// Core
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyProvider } from "../context/context"

// Components
import { Navbar, Footer } from "../components"

// Styles
import "../styles/main.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <GatsbyProvider>
      <div className="site-content">
        <Navbar siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main className="site-main">{children}</main>
        <Footer siteTitle={data.site.siteMetadata?.title || `Title`} />
      </div>
    </GatsbyProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
